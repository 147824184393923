import React, { useState, useEffect } from 'react';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import { Button } from '../../../Molecules/Button/Button';
import Checkbox from '../../../Molecules/Checkbox/Checkbox';
import CtaSection from '../../../components/ContentType/Section/CtaSection/CtaSection';
import DocumentSection from '../../../components/ContentType/Section/DocumentSection/DocumentSection';
import Dropdown from '../../../components/Dropdown/Dropdown';
import ScrollPageNav from '../../../components/ScrollPageNav/ScrollPageNav';
import {
  FormattedMessage,
  useIntl,
} from '../../../../plugins/publicis-gatsby-plugin-i18n';
import { getPieChartConfigData, removeHtmlTag } from '../../../Utils/Utils';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
  HorizontalYellowLineShape,
  ShapeRightAlignedTwoLines,
} from '../../../Atoms/Icons/Icons';
import Layout from '../../../Layout';
import PageBanner from '../../../components/PageBanner/PageBanner';
import Seo from '../../../components/Seo/Seo';
import ShapeBackground from '../../../Molecules/ShapeBackground/ShapeBackground';
import TitlePage from '../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';
import VideoSection from '../../../components/ContentType/Section/VideoSection/VideoSection';
import VisuelComponent from '../../../components/ContentType/VisuelComponent/VisuelComponent';

import './styles.scss';
import useMedias from '../../../hooks/useMedias';
import { trackCtaPeriodique, trackDate } from '../../../Utils/Tracking';


let classNames = require('classnames');

const FinancePagesTemplate = ({ data, pageContext }) => {
  const intl = useIntl();
  const { getImage, getDocument, processUrl } = useMedias();

  console.log(pageContext)

  const imagesArray = data.allImages.edges;
  const documentsArray = data.allDocuments.edges;
  const financeData = data.financeData.edges[0].node;
  const reunionData = data.reunionData.edges;
  const generalAssemblyData = data.generalAssemblyData.edges;
  const reglementData = data.informationReglementeesData.edges;
  const agendaData = data.agendaData.edges;
  const langCode = pageContext.locale;
  const years = [];

  const [selectedYear, setSelectedYear] = useState('');
  const [displayContent, setDisplayContent] = useState([]);
  const [isAgenda, setIsAgenda] = useState(false);
  const [pageName, setPageName] = useState();
  const [infoFinance, setInfoFinance] = useState(false);
  const [filterValue, setFilterValue] = useState('filter_all_1');
  const [donutsDataSales, setDonutsDataSales] = useState([]);
  const [donutsDataCapital, setDonutsDataCapital] = useState([]);
  const [showItem, setShowItem] = useState(4);
  const [agendaContact, setAgendaContact] = useState();
  const [innerNav, setNav] = useState([]);
  const metaTags = financeData.metatag;
  let metaTitle = '';
  let metaDesc = '';

  metaTags.forEach((meta) => {
    if (meta.attributes.name === 'title') {
      metaTitle = meta.attributes.content;
    }
    if (meta.attributes.name === 'description') {
      metaDesc = meta.attributes.content;
    }
  });

  let reunionContent = [];
  let lists_agenda = [];
  let agendaContactObj = {};
  let typeOfContent = '';
  let yearInit = '';
  let searchQuery = '';
  let innerNavArr = [];

  const onClickShowMore = () => {
    if (showItem < lists_agenda?.length) {
      setShowItem(showItem + 4);
    }
  };

  const setInnerNav = (className_, title) => {
    let t = title?.replace(/<[^>]*>?/gm, '');
    if (
      innerNavArr.filter((a) => a?.section?.includes(className_) === true)
        .length <= 0
    ) {
      innerNavArr.push({
        title: t,
        section: className_,
      });
    }
  };

  const onYearChange = (year_selected) => {
    setSelectedYear(year_selected?.label);
    trackDate('clic_selected_date', 'information_reglementees', 'finance', year_selected?.label)
  };

  const filterList = (id) => {
    setFilterValue(id);
    let currCheckbox = document.querySelector(`#${id}`).closest('.checkbox')
    let buttonContent = currCheckbox.querySelector('.text').textContent
    trackCtaPeriodique('clic_periodicite', 'information_reglementees', 'finance', selectedYear, buttonContent)
  };

  const setUrlParamVal = (openTabId, year) => {
    let openTab;
    let returnVal = '';
    if (openTabId === 6) {
      openTab = 'news';
    } else if (openTabId === 7) {
      openTab = 'press-release';
    } else if (openTabId === 8) {
      openTab = 'publications';
    } else {
      returnVal = '?openYear=' + selectedYear;
    }

    if (openTab) returnVal = '?openYear=' + year;

    return returnVal;
  };

  const setData = (block, contentType) => {
    let i = 0;
    typeOfContent = contentType;

    if (contentType === 'agenda') {
      lists_agenda = [];
      block?.forEach((agenda) => {
        let agendaDate = agenda?.node?.field_date?.split(' ');
        lists_agenda.push({
          date: {
            day: agendaDate[0],
            month: agendaDate[1],
          },
          title: agenda?.node?.title,
        });
      });
    } else {
      block.forEach((block) => {
        if (block.node.langcode === langCode) {
          //set drop down to available years
          years.push({
            id: i++,
            label: block?.node?.relationships?.field_year?.name,
          });

          if (
            typeof window !== 'undefined' &&
            typeof location !== 'undefined'
          ) {
            searchQuery =
              new URLSearchParams(location?.search).get('openYear') || '';
            if (searchQuery) {
              yearInit = searchQuery;
            } else {
              yearInit = years[0]?.label;
            }
          }

          let tempFileArr = [];
          block.node.relationships.field_blocs.forEach((item) => {
            switch (item.__typename) {
              case 'block_content__block_video':
                reunionContent.push({
                  year: block.node?.relationships?.field_year?.name,
                  content: [
                    {
                      type: 'video',
                      link: item.field_link?.uri,
                      title: removeHtmlTag(item.field_title?.processed),
                      image: getImage(
                        imagesArray,
                        item.relationships?.field_image?.drupal_internal__mid
                      ),
                      alt: item.relationships?.field_image?.field_media_image?.alt || removeHtmlTag(item.field_title?.processed)
                    },
                  ],
                });
                break;

              case 'block_content__block_document':
                tempFileArr = [];
                item.relationships?.field_file.forEach((file) => {
                  tempFileArr.push(
                    getDocument(documentsArray, file?.drupal_internal__mid)
                  );
                });

                tempFileArr[0] ? Object?.assign(tempFileArr[0], { xhtmlLink: getDocument(documentsArray, item?.relationships?.field_media_xhtml?.drupal_internal__mid)?.uri?.url }) : null;

                reunionContent.push({
                  year: block.node?.relationships?.field_year?.name,
                  title: removeHtmlTag(item?.field_title?.processed),
                  category: item?.field_category,
                  content: [
                    {
                      type: 'document',
                      files: tempFileArr,
                    },
                  ],
                });

                break;

              case 'block_content__block_cta':
                let openParam =
                  item.relationships?.field_media_category
                    ?.drupal_internal__tid;
                reunionContent.push({
                  year: block.node?.relationships?.field_year?.name,
                  category: item?.field_category,
                  content: [
                    {
                      type: 'cta',
                      title: removeHtmlTag(item?.field_title.processed),
                      link:
                        item?.field_link?.url +
                        setUrlParamVal(
                          openParam,
                          block.node?.relationships?.field_year?.name
                        ),
                      target: item?.field_nouvelle_fenetre ? '_blank' : '_self',
                      button_title: item?.field_link?.title,
                    },
                  ],
                });

                break;
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    let tempGraphSales = [];
    let tempGraphCapital = [];

    financeData.relationships?.field_blocs?.forEach((bloc) => {
      bloc.relationships?.field_bloc_texte?.forEach((block, index) => {
        const tempGraphData = getPieChartConfigData();

        if (
          block.relationships?.field_graph &&
          block.relationships?.field_graph.length === 2
        ) {
          if (window.innerWidth < 414) {
            tempGraphData.series[0].dataLabels.alignTo = 'plotEdges';
          }
          tempGraphData.series[0].dataLabels.distance = 20;
        }

        if (
          block.relationships?.field_graph &&
          block.relationships?.field_graph.length === 5
        ) {
          tempGraphData.responsive.rules[0].condition.maxWidth = 480;
        }

        block.relationships?.field_graph?.forEach((graphData) => {
          tempGraphData.series[0].data.push({
            x: 1,
            name: graphData?.field_title?.processed || 'Default',
            y: Number(graphData?.field_chiffre),
            color: graphData?.field_couleur || '#000000',
          });
        });

        if (tempGraphData.series[0].data.length > 0) {
          if (bloc?.field_type === 'structure') {
            tempGraphData.series[0].dataLabels.style.color = '#ffffff';

            tempGraphCapital.push({
              type: bloc?.field_type,
              title: block?.field_title?.processed,
              graphData: tempGraphData,
            });
          } else {
            tempGraphSales.push({
              type: bloc?.field_type,
              title: block?.field_title?.processed,
              graphData: tempGraphData,
            });
          }
        }
      });
    });
    setDonutsDataSales(tempGraphSales);
    setDonutsDataCapital(tempGraphCapital);
    setNav(innerNavArr);
  }, []);

  useEffect(() => {
    let tempArr = [];

    setDisplayContent([]);

    selectedYear === '' && setSelectedYear(yearInit);
    typeOfContent === 'chiffres_cles_finance' && setInfoFinance(true);

    if (typeOfContent === 'agenda') {
      setIsAgenda(true);
      setAgendaContact(agendaContactObj);
    }

    setPageName(typeOfContent);
    reunionContent.forEach((item) => {
      if (item.year === selectedYear) {
        tempArr.push(item);
      }
    });
    setDisplayContent(tempArr);

    return () => setDisplayContent([]);
  }, [selectedYear]);

  return (
    <Layout>
      <Seo
        title={financeData?.field_metatag?.title ? financeData?.field_metatag?.title : metaTitle}
        description={financeData?.field_metatag?.description ? financeData?.field_metatag?.description : metaDesc}
      />
      <div
        className={classNames(
          'page_template',
          'page_finance',
          'page_' + pageName
        )}
      >
        {financeData?.field_progress && <ScrollPageNav data={innerNav} />}
        <PageBanner
          visuel={getImage(
            imagesArray,
            financeData?.relationships?.field_image?.drupal_internal__mid
          )}
          alt={financeData.relationships?.field_image?.field_media_image?.alt || ''}
          scroll={true}
        >
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                parentPage: { title: 'Finance', url: false },
                currentPage: {
                  title: financeData?.title,
                  url: '',
                },
                locale: langCode,
              }}
            />

            <div
              className={
                financeData?.field_link?.title &&
                financeData?.field_link?.url &&
                'title_audio'
              }
            >
              {' '}
              {/* !!!!!! if audio add title_autio if not put nothing */}
              <TitlePage
                color="color_white"
                title={financeData?.title}
                description={
                  financeData?.field_sous_titre &&
                  !financeData?.field_sous_titre?.includes(
                    'dummy_text_not_to_display'
                  ) &&
                  '<p>' + financeData?.field_sous_titre + '</p>'
                }
              />
              {financeData?.field_link?.title &&
                financeData?.field_link?.url && (
                  <Button
                    label={financeData?.field_link?.title}
                    link={financeData?.field_link?.url}
                    target="_blank"
                  />
                )}
            </div>
          </div>
        </PageBanner>

        {financeData?.relationships?.field_blocs?.map((block, uniqueID) => {
          let i = uniqueID;
          let tempDocArr = [];
          let allDocArr = [];
          console.log(block.__typename)
          switch (block.__typename) {

            case 'block_content__block_list_content':
              switch (block?.field_content_list) {
                case 'assemblee_generale':
                case 'reunion_analystes':
                  setData(
                    block?.field_content_list === 'reunion_analystes'
                      ? reunionData
                      : generalAssemblyData,
                    block?.field_content_list
                  );
                  return (
                    <div key={i}>
                      <div className="page_content wrapper_page">
                        <div className="page_filters">
                          <div className="filters">
                            <div className="field">
                              <p className="label_dropdown">
                                <FormattedMessage id="finance.selectYear" />
                              </p>
                              <Dropdown
                                customClass="dropdown-years"
                                list={years}
                                selected={
                                  years.filter(
                                    (a) => a.label === selectedYear
                                  )[0]
                                }
                                onChange={onYearChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="filtered_content_blocks">
                        {displayContent.map((block, j) => (
                          <div key={j}>
                            {block.content[0]?.type === 'video' ? (
                              <VideoSection videoDetails={block}></VideoSection>
                            ) : null}
                            {block.content[0]?.type === 'document' ? (
                              <DocumentSection
                                documentDetails={block}
                              ></DocumentSection>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    </div>
                  );

                case 'informations_reglementees':
                  setData(reglementData, block?.field_content_list);

                  return (
                    <div key={i}>
                      <div className="page_content wrapper_page">
                        <div className="page_filters">
                          <div
                            className="legal_info"
                            dangerouslySetInnerHTML={{
                              __html: financeData?.body?.processed,
                            }}
                          ></div>
                          <div className="filters">
                            <div className="field">
                              <label>
                                <FormattedMessage id="finance.selectYear" />
                              </label>
                              <Dropdown
                                customClass="dropdown-years"
                                list={years}
                                onChange={onYearChange}
                              />
                            </div>
                            <fieldset
                              className="field checkbox_container"
                              aria-label={intl.formatMessage({
                                id: 'ariaLabel.select_periodic_permanant_info',
                              })}
                              role="radiogroup"
                            >
                              <Checkbox
                                change={filterList}
                                field={{
                                  id: 'filter_all_1',
                                  filed_type: 'checkbox',
                                  type: 'radio',
                                  name: 'filter_radio',
                                  label: intl.formatMessage({
                                    id: 'finance.all',
                                  }),
                                  ariaLabelOfLabel: intl.formatMessage({
                                    id: 'ariaLabel.all_info',
                                  }),
                                  value:
                                    filterValue === 'filter_all_1'
                                      ? true
                                      : false,
                                }}
                              />
                              <Checkbox
                                change={filterList}
                                field={{
                                  id: 'infos_periodic',
                                  filed_type: 'checkbox',
                                  type: 'radio',
                                  name: 'filter_radio',
                                  label: intl.formatMessage({
                                    id: 'finance.infoPeriodic',
                                  }),
                                  value:
                                    filterValue === 'infos_periodic'
                                      ? true
                                      : false,
                                }}
                              />
                              <Checkbox
                                change={filterList}
                                field={{
                                  id: 'infos_permanent',
                                  filed_type: 'checkbox',
                                  type: 'radio',
                                  name: 'filter_radio',
                                  label: intl.formatMessage({
                                    id: 'finance.infoPermanant',
                                  }),
                                  value:
                                    filterValue === 'infos_permanent'
                                      ? true
                                      : false,
                                }}
                              />
                            </fieldset>
                          </div>
                        </div>
                      </div>

                      <div className="filtered_content_blocks">
                        {displayContent.map((block, j) => (
                          <div key={j}>
                            {block?.content[0]?.type === 'cta' &&
                              (filterValue === block?.category ||
                                filterValue === 'filter_all_1') ? (
                              <CtaSection ctaDetails={block}></CtaSection>
                            ) : null}
                            {block?.content[0]?.type === 'document' &&
                              (filterValue === block?.category ||
                                filterValue === 'filter_all_1') ? (
                              <DocumentSection
                                documentDetails={block}
                                currPage="information_reglementees"
                              ></DocumentSection>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    </div>
                  );

                case 'agenda':
                  setData(agendaData, block?.field_content_list);
                  return (
                    <div className="page_content wrapper_page" key={i}>
                      {agendaContact?.title && (
                        <div className="page_intro">
                          <p>
                            {agendaContact?.title}
                            <HorizontalYellowLineShape />
                          </p>
                          <div className="contact">
                            <a href="#" className="phone">
                              {agendaContact?.phone}
                            </a>
                            <a
                              href={`mailto:${agendaContact?.email}`}
                              className="email"
                              target="_blank"
                            >
                              {agendaContact?.email}
                            </a>
                          </div>
                        </div>
                      )}

                      <div className="agenda_container">
                        <ul>
                          {lists_agenda.map((agenda, index) => (
                            <li
                              key={index}
                              className={classNames({ show: index < showItem })}
                            >
                              <p className="date">
                                <span className="day">{agenda?.date?.day}</span>
                                <span className="month">
                                  {agenda?.date?.month
                                    ?.split('.')
                                    .join('')
                                    ?.toUpperCase()}
                                </span>
                                <HorizontalYellowLineShape />
                              </p>
                              <p className="title">{agenda?.title}</p>
                            </li>
                          ))}
                        </ul>
                        <div className="btn_container">
                          <Button
                            label={intl.formatMessage({
                              id: 'common.viewMore',
                            })}
                            icon="plus"
                            onClick={onClickShowMore}
                            customClass={
                              showItem >= lists_agenda?.length ? 'hide' : ''
                            }
                            aria-label={intl.formatMessage({
                              id: 'common.showMoreDetails',
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  );

                default:
                  break;
              }
              break;

            case 'block_content__block_document':
              setInnerNav('scrollTo_' + i, block?.field_title?.processed);
              block?.relationships?.field_file?.forEach((file) => {
                tempDocArr.push(
                  getDocument(documentsArray, file?.drupal_internal__mid)
                );
              });

              tempDocArr[0] ? Object.assign(tempDocArr[0], { xhtmlLink: getDocument(documentsArray, block?.relationships?.field_media_xhtml?.drupal_internal__mid)?.uri?.url }) : null;

              allDocArr.push({
                title: removeHtmlTag(block?.field_title?.processed),
                content: [
                  {
                    type: 'document',
                    files: tempDocArr,
                  },
                ],
              });

              return (
                <DocumentSection
                  documentDetails={allDocArr[0]}
                  titleSection={true}
                  hasBackground={block?.field_background}
                  sectionClass={'scrollTo_' + i}
                  key={i}
                  currPage="page_finance"
                ></DocumentSection>
              );

            case 'block_content__block_chiffres_cles_finance':
              typeOfContent = 'chiffres_cles_finance';
              setInnerNav('scrollTo_' + i, block?.field_title?.processed);
              switch (block.field_type) {
                case 'chiffres':
                  return (
                    <section
                      className={classNames(
                        'section_content',
                        'section_financial_key_numbers',
                        'scrollTo_' + i
                      )}
                      key={i}
                    >
                      <div className="wrapper_page">
                        <TitleSection
                          title={block?.field_title?.processed}
                          type="line"
                        />
                      </div>

                      <div className="wrapper_page_xs col_2">
                        <div className="col">
                          {block.relationships?.field_bloc_texte?.map(
                            (data, i_id) => (
                              <div
                                className={classNames('desktop_half_block', {
                                  last:
                                    i_id ===
                                    block.relationships?.field_bloc_texte
                                      .length -
                                    1,
                                })}
                                key={i_id}
                              >
                                {data?.field_bloc_text_1 && (
                                  <h3>{data?.field_title?.processed}</h3>
                                )}
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: data.field_bloc_text_1?.processed,
                                  }}
                                ></div>
                              </div>
                            )
                          )}

                          {/* any graph modification done on mobile must be done on desktop version as well */}
                          {donutsDataSales.map((graph, k_id) => (
                            <div key={k_id}>
                              {graph.type === block?.field_type && (
                                <div
                                  className="only_mobile_tablet"
                                  aria-hidden="true"
                                >
                                  <h3>{graph.title}</h3>
                                  {graph?.graphData && (
                                    <HighchartsReact
                                      highcharts={Highcharts}
                                      options={graph.graphData}
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                        {/* any graph modification done on desktop must be done on mobile version as well */}
                        <div className="col only_desktop">
                          {donutsDataSales.map((graph, k_id) => (
                            <div key={k_id}>
                              {graph.type === block?.field_type && (
                                <div
                                  className={classNames(
                                    'desktop_half_block',
                                    'desktop_right'
                                  )}
                                >
                                  <h3>{graph.title}</h3>
                                  {graph?.graphData && (
                                    <HighchartsReact
                                      highcharts={Highcharts}
                                      options={graph.graphData}
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </section>
                  );

                case 'action':
                  return (
                    <section
                      className={classNames(
                        'section_content',
                        'section_action',
                        'scrollTo_' + i
                      )}
                      key={i}
                    >
                      <ShapeBackground
                        top="right"
                        color="catskill_white"
                        bottomInfinite={true}
                      >
                        <div className="wrapper_page_xs">
                          <TitleSection
                            title={block.field_title?.processed}
                            type="arrow"
                          />

                          <div className="col_2">
                            {block.relationships?.field_bloc_texte?.map(
                              (actionData, j) => (
                                <div key={j}>
                                  {!actionData?.relationships?.field_image && (
                                    <div
                                      className={classNames(
                                        'col',
                                        block?.relationships?.field_bloc_texte
                                          .length <
                                          block.relationships?.field_bloc_texte
                                            ?.length -
                                          1
                                          ? 'first'
                                          : 'last'
                                      )}
                                    >
                                      {actionData.field_title && (
                                        <h3>
                                          {actionData.field_title?.processed}
                                        </h3>
                                      )}
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            actionData?.field_bloc_text_1
                                              ?.processed,
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              )
                            )}
                          </div>

                          <div className="col_half"></div>
                          {block.relationships?.field_bloc_texte?.map(
                            (graphImg, key) => (
                              <div key={key}>
                                {graphImg.relationships?.field_image && (
                                  <div className="curved_graph">
                                    <h3>{graphImg.field_title?.processed}</h3>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          graphImg?.field_bloc_text_1
                                            ?.processed,
                                      }}
                                    />
                                    <div className="visuel">
                                      <VisuelComponent
                                        visuel={getImage(
                                          imagesArray,
                                          graphImg.relationships?.field_image
                                            ?.drupal_internal__mid
                                        )}
                                        alt={graphImg.relationships?.field_image?.field_media_image?.alt || ''}
                                      ></VisuelComponent>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )
                          )}
                        </div>
                      </ShapeBackground>
                    </section>
                  );

                case 'structure':
                  return (
                    <section
                      className={classNames(
                        'section_content',
                        'section_structure_capital',
                        'scrollTo_' + i
                      )}
                      key={i}
                    >
                      <ShapeBackground
                        top="left"
                        bottom="left"
                        color="dark_bleu"
                      >
                        <div className="wrapper_page_xs">
                          <TitleSection
                            title={block.field_title?.processed}
                            type="arrow"
                            h2color="white"
                          />
                          <div className="col_stairs">
                            {donutsDataCapital.map((graph, index) => (
                              <div className="col" key={index}>
                                <h3>{graph.title}</h3>
                                <div className="graph_container">
                                  {graph?.graphData && (
                                    <HighchartsReact
                                      highcharts={Highcharts}
                                      options={graph.graphData}
                                    />
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </ShapeBackground>
                    </section>
                  );
              }

              break;

            case 'block_content__block_agenda':
              Object.assign(agendaContactObj, {
                title: block.field_title?.processed,
                email: block.field_email,
                phone: block.field_telephone,
              });
              break;

            default:
              break;
          }
        })}

        {(infoFinance || isAgenda) && (
          <ShapeRightAlignedTwoLines className="shape_right_aligned_two_lines" />
        )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query MainFinanceTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
    $documentsID: [Int!]
  ) {
    financeData: allNodeFinance(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          drupal_id
          langcode
          path {
            alias
          }
          body {
            processed
          }
          field_link {
            title
            url
          }
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          field_sous_titre
          title
          field_progress
          relationships {
            field_image {
              drupal_internal__mid
              field_media_image{
                alt
              }
            }
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__block_document {
                  field_title {
                    processed
                  }
                  field_background
                  langcode
                  relationships {
                    field_file {
                      drupal_internal__mid
                    }
                    field_media_xhtml {
                      drupal_internal__mid
                    }
                  }
                }
                ... on block_content__block_list_content {
                  field_content_list
                }

                ... on block_content__block_agenda {
                  field_email
                  field_telephone
                  field_title {
                    processed
                  }
                }

                ... on block_content__block_chiffres_cles_finance {
                  field_title {
                    processed
                  }
                  field_type
                  relationships {
                    field_bloc_texte {
                      field_title {
                        processed
                      }
                      field_bloc_text_1 {
                        processed
                      }
                      relationships {
                        field_graph {
                          field_chiffre
                          field_couleur
                          field_title {
                            processed
                          }
                        }
                        field_image {
                          drupal_internal__mid
                          field_media_image{
                            alt
                          }
                        }
                      }
                    }
                  }
                  langcode
                }
              }
            }
          }
        }
      }
    }

    reunionData: allNodeReunion(
      sort: { order: DESC, fields: relationships___field_year___name }
    ) {
      edges {
        node {
          title
          langcode
          relationships {
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__block_video {
                  id
                  field_title {
                    processed
                  }
                  field_link {
                    uri
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                  }
                }

                ... on block_content__block_document {
                  field_title {
                    processed
                  }
                  field_background
                  relationships {
                    field_file {
                      drupal_internal__mid
                    }
                    field_media_xhtml {
                      drupal_internal__mid
                    }
                  }
                }
              }
            }
            field_year {
              name
              id
            }
          }
        }
      }
    }

    generalAssemblyData: allNodeAssembleeGenerale(
      sort: { order: DESC, fields: relationships___field_year___name }
    ) {
      edges {
        node {
          title
          langcode
          relationships {
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__block_video {
                  id
                  field_title {
                    processed
                  }
                  field_link {
                    uri
                  }
                }
                ... on block_content__block_document {
                  field_title {
                    processed
                  }
                  field_background
                  relationships {
                    field_file {
                      drupal_internal__mid
                    }
                    field_media_xhtml {
                      drupal_internal__mid
                    }
                  }
                }
              }
            }
            field_year {
              name
              id
            }
          }
        }
      }
    }

    informationReglementeesData: allNodeInformationsReglementees(
      sort: { order: DESC, fields: relationships___field_year___name }
    ) {
      edges {
        node {
          langcode
          relationships {
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__block_cta {
                  field_title {
                    processed
                  }
                  field_link {
                    url
                    title
                  }
                  field_category
                  field_nouvelle_fenetre
                  relationships {
                    field_media_category {
                      name
                      drupal_internal__tid
                    }
                  }
                }
                ... on block_content__block_document {
                  field_title {
                    processed
                  }
                  field_category
                  relationships {
                    field_file {
                      drupal_internal__mid
                    }
                    field_media_xhtml {
                      drupal_internal__mid
                    }
                  }
                }
              }
            }
            field_year {
              name
            }
          }
        }
      }
    }

    agendaData: allNodeAgenda(
      filter: { langcode: { eq: $locale } }
      sort: { fields: field_date, order: ASC }
    ) {
      edges {
        node {
          title
          field_date(formatString: "DD MMM", locale: $locale)
        }
      }
    }

    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
                gatsby_medium_carousel
                gatsby_thumbnail_carousel
                gatsby_innov_carousel
                gatsby_activites_carousel        
              }
            }
          }
        }
      }
    }

    allDocuments: allMediaDocument(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $documentsID }
      }
    ) {
      edges {
        node {
          langcode
          field_media_document {
            description
          }
          relationships {
            field_media_document {
              filesize
              filename
              filemime
              uri {
                url
              }
            }
          }
          drupal_internal__mid
        }
      }
    }
  }
`;

FinancePagesTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default FinancePagesTemplate;
