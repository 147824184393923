import React from 'react';
import { Button } from '../../../../Molecules/Button/Button'
import TitleBlock from '../../../../Molecules/TitleBlock/TitleBlock';

var classNames = require('classnames');

const CtaSection = ({ ctaDetails }) => {

  let ctaData = ctaDetails.content[0];

  return (
    <div className="reports_container">
      <div className="wrapper_page">
        <TitleBlock
          headingTag="h3"
          title={ctaData.title}
          type="arrow"
          iconColor="color_dark_bleu"
        />
        <Button
          label={ctaData.button_title}
          link={ctaData.link}
          target={ctaData.target}
        />
      </div>
    </div>
  );
};

export default CtaSection;
